import { render, staticRenderFns } from "./StyleEditTabFiles.vue?vue&type=template&id=ff54680e&scoped=true&"
import script from "./StyleEditTabFiles.vue?vue&type=script&lang=js&"
export * from "./StyleEditTabFiles.vue?vue&type=script&lang=js&"
import style0 from "./StyleEditTabFiles.vue?vue&type=style&index=0&id=ff54680e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff54680e",
  null
  
)

export default component.exports