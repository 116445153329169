<template>
  <div>
    <section class="grid-view">
      <b-container>
        <b-row>
          <template
            v-for="media in styleMedia"
          >
            <b-col
              v-if="!(isClient && media.is_internal)"
              :key="media.id"
              no-body
              class="file-card-box"
            >
              <b-card>
                <b-card-text
                  text-tag="div"
                >
                  <div :class="media.is_cover ? 'image-file iscover' : 'image-file'">
                    <b-img
                      v-if="media.image"
                      :alt="`${media.name}`"
                      rounded
                      :src="media.image"
                    />
                    <b-link
                      v-if="media.file"
                      target="blank"
                      class="nopreview"
                      :href="media.file"
                    >{{ media.name }}</b-link>
                    <b-img
                      v-if="media.static_file"
                      :alt="`${media.name}`"
                      rounded
                      height="160"
                      :src="media.static_file"
                    />
                  </div>
                  <div class="mt-1 d-flex flex-row">
                    <!-- Tag -->
                    <b-badge
                      v-for="tag in splitTag(media.tag)"
                      :key="tag"
                      pill
                      variant="secondary"
                      class="mt-1 mb-1"
                    >{{ tag }}</b-badge>

                    <!-- Internal -->
                    <b-badge
                      v-if="media.is_internal"
                      pill
                      variant="primary"
                      class="mt-1 mb-1"
                    >
                      Internal
                    </b-badge>

                    <!-- Action Buttons -->
                    <b-dropdown
                      variant="link"
                      dropup
                      :disabled="!canEditStyle"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        variant="danger"
                      >
                        <div
                          @click="removeMedia(media.id)"
                        >Remove File</div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="media.image"
                      >
                        <div
                          @click="setCover(media.id, !media.is_cover)"
                        >
                          {{ media.is_cover ? "Uncover Style" : "Set as Style Cover" }}
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <div
                          @click="setPOMCover(media.id, media.tag)"
                        >
                          Set as POM Cover
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="!isClient"
                      >
                        <div
                          @click="setInternal(media.id, !media.is_internal)"
                        >
                          {{ media.is_internal ? "Set as Public" : "Set as Internal Only" }}
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-container>
    </section>
    <div class="d-flex justify-content-center">
      <b-jumbotron
        bg-variant="light"
        class="justify-content-center w-75 m-5"
      >
        <b-form-file
          v-model="files"
          multiple
          placeholder="Choose files or drop them here..."
          size="lg"
          :disabled="!canEditStyle"
          @input="filesUpload"
        />
      </b-jumbotron>
    </div>

  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardText,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BImg,
  BJumbotron,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BImg,
    BJumbotron,
    BLink,
    BRow,
  },
  props: {
    styleMedia: {
      type: Array,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  setup(props, { emit }) {
    const toast = useToast()
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isClient = userRole === 'client-admin' || userRole === 'client'

    const files = ref([])

    const filesUpload = () => {
      if (files.value) {
        const styleMediaUpload = new FormData()
        files.value.forEach(upload => {
          styleMediaUpload.append('files', upload)
        })
        store.dispatch('app-style/addStyleMedia', {
          id: router.currentRoute.params.id,
          styleMediaUpload,
        })
          .then(() => {
            emit('fetch-style-media')
            files.value = []
          })
      }
    }

    const removeMedia = id => {
      store.dispatch('app-style/removeStyleMedia', { id })
        .then(() => {
          emit('fetch-style-media')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'File removed',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const setCover = (id, isCover) => {
      store.dispatch('app-style/updateStyleMedia', { id, is_cover: isCover })
        .then(() => {
          emit('fetch-style-media')
        })
    }

    const setInternal = (id, isInternal) => {
      store.dispatch('app-style/updateStyleMedia', { id, is_internal: isInternal })
        .then(() => {
          emit('fetch-style-media')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: isInternal ? 'Set as Internal' : 'Set as Public',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const setPOMCover = (id, oldTag) => {
      let newTag
      if (!oldTag || oldTag === 'POM') {
        newTag = 'POM Cover'
      } else {
        const newTags = oldTag.split(',').filter(tag => tag !== 'POM')
        newTags.push('POM Cover')
        newTag = newTags.join(',')
      }
      store.dispatch('app-style/updateStyleMedia', { id, tag: newTag })
        .then(() => {
          emit('fetch-style-media')
        })
    }

    const splitTag = tag => {
      if (!tag) {
        return []
      }
      return tag.split(',')
    }

    return {
      filesUpload,
      removeMedia,
      setCover,
      setPOMCover,
      setInternal,
      splitTag,

      files,
      isClient,
    }
  },
}
</script>

<style lang="scss" scoped>
.file-card-box {
  flex: 0 1 300px;
  overflow:hidden;
}
.image-file img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  vertical-align: middle;
  border-radius: 15px;
}
.image-file .nopreview {
  object-fit: cover;
  width: 100%;
  height: 180px;
  display:flex;
}
.noimage-file {
  margin: 0 auto 0 auto;
  height: 180px;
  border-radius: 15px;
}
.iscover {
  outline:10px solid;
  border-radius: 15px;
}
</style>
